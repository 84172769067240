;(function($) {
	personalization = {
		init: function( module ) {
			var url = '/personalization.json';

			if ( typeof module != "undefined" ) {
				url = '/personalization.json?module=' + module;
			}

			$.get( url, function( data ) {
				// Loop through each module
				$.each(data, function(i) {
					var aryModule = data[ i ];

					// Loop through settings in module
					$.each(aryModule.settings, function(j) {
						var stcSetting = aryModule.settings[ j ];
						var elements = $("[data-personalization-module='" + stcSetting.module + "'][data-personalization-setting='" + stcSetting.setting + "']");

						// Loop through matched elements in DOM based on setting name
						$.each(elements, function(k) {
							var element = elements[ k ];

							switch ( stcSetting.type ) {
								case 'attribute':
									$(element).attr( stcSetting.key, stcSetting.value );
									break;

								case 'property':
									$(element).prop( stcSetting.key, stcSetting.value );
									break;

								case 'addClass':
									$(element).addClass( stcSetting.value );
									break;

								case 'removeClass':
									$(element).removeClass( stcSetting.value );
									break;

								case 'value':
									$(element).val( stcSetting.value );
									break;

								case 'html':
									$(element).html( stcSetting.value );
									break;

								default:
									break;
							}
						});
					});
				});
			});
		},

		stores: {
			homepagecontenbox: function() {
				$.get( '/personalization/stores/homepagecontentbox.json', function( data ) {
					$('.card[data-personalization-module="stores"]').html( data.html );

					if ( data.class ) {
						$('.card[data-personalization-module="stores"]').addClass( data.class );
					}
				});
			},

			mystorebutton: function() {
				$.get( '/personalization/stores/mystorebutton.json', function( data ) {
					if ( data.cookieStoreID ) {
						$( '.make-my-store[data-storeid="'+ data.cookieStoreID +'"]' ).hide();
						$( '.my-store[data-storeid="'+ data.cookieStoreID +'"]' ).show();
					}
					else {
						$( '.my-store[data-storeid="'+ data.cookieStoreID +'"]' ).hide();
						$( '.make-my-store[data-storeid="'+ data.cookieStoreID +'"]' ).show();
					}
				});
			}
		},

		meatCounter: {
			featuredItem: function( pagePath ) {
				$.get( '/personalization/meatcounter/featureditem.json', function( data ) {
					$('.card[data-personalization-setting="featuredItem"]').html( data.html.replace( /%_pagePath_%/g, pagePath ) );
				});
			}
		},

		bakery: {
			featuredItem: function( pagePath ) {
				$.get( '/personalization/bakery/featureditem.json', function( data ) {
					$('.card[data-personalization-setting="featuredItem"]').html( data.html.replace( /%_pagePath_%/g, pagePath ) );
				});
			}
		},

		recipes: {
			accounts: function() {
				$.get( '/personalization/recipes/accounts.json', function(data) {
					if (data.loggedIn) {
						$('.cart-links .first-filter-link').hide();
						$('.filter-link').not('.first-filter-link').html(data.logOutLabel);
						$('.filter-link').not('.first-filter-link').attr('href', data.logOutUrl);
						$('.filter-link').not('.first-filter-link').css('border-left', 'none');
					}
					else {
						$('.cart-links .first-filter-link').show();
						$('.filter-link').not('.first-filter-link').html(data.logInLabel);
						$('.filter-link').not('.first-filter-link').attr('href', data.logInUrl);
					}

					$('.filter-link').not('.first-filter-link').show();
				});
			},

			buttons: function(namespace) {

			}
		}
	};
})(jQuery);
